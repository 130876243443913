<template>
  <LiefengContent>
    <template #title>沟通组</template>
    <template #toolsbarRight>
      <DatePicker
        v-model="searchDate"
        type="daterange"
        placement="bottom-end"
        placeholder="根据时段查询"
        style="width: 200px"
      ></DatePicker>
      <Button
        type="primary"
        style="margin: 0 10px"
        icon="ios-search"
        @click="search"
        >查询</Button
      >
      <Button type="success" icon="ios-download-outline" @click="exportExcel">导出报表</Button>
    </template>
    <template #contentArea>
      <div class="container">
        <Tabs value="3" @on-click="taggleTab" :animated="false" type="card">
          <TabPane label="按区" name="3"></TabPane>
          <TabPane label="按街道" name="4"></TabPane>
          <TabPane label="按社区" name="5"></TabPane>
        </Tabs>
        <LiefengTable
          :tableData="tableData"
          :talbeColumns="talbeColumns"
          :loading="loading"
          :pageSize="pageSize"
          :pagesizeOpts="[20, 30, 50, 100, 200]"
          :total="total"
          :height="tableHeight"
          :curPage="page"
          showsummary
          :summaryData="summaryData"
          @hadlePageSize="hadlePageSize"
        ></LiefengTable>
      </div>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/suiyuestatisticsmobilize")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      searchDate: [],
      tableData: [],
      basicColumn: [
        {
          title: "总沟通组数",
          key: "groupNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "总群里人数",
          key: "memberNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "总家庭户代表数",
          key: "contactNum",
          minWidth: 100,
          align: "center",
        },
        {
          title: "总消息数",
          key: "messageNum",
          minWidth: 100,
          align: "center",
        },
      ],
      talbeColumns: [],

      loading: false,
      pageSize: 20,
      tableHeight: "",
      page: 1,
      total: 0,
      orgLevel: "3",
      summaryData: {},
    };
  },
  methods: {
    //   导出
    exportExcel() {
        let params = {
            orgLevel: this.orgLevel,
            startDate: '',
            endDate: ''
        };
        if (this.searchDate[0] && this.searchDate[1]) {
        params.startDate = this.$core.formatDate(
          new Date(this.searchDate[0]),
          "yyyy-MM-dd"
        );
        params.endDate = this.$core.formatDate(
          new Date(this.searchDate[1]),
          "yyyy-MM-dd"
        );
      }
      location.href = vue.getProxy()["/statistic"].target + '/api/symanage/pc/onlineHome/exportGroup?orgLevel=' + this.orgLevel + '&startDate=' + params.startDate + '&endDate=' + params.endDate + '&oemCode=' + parent.vue.oemInfo.oemCode;
    },
    taggleTab(name) {
      if (name == "3") {
        this.talbeColumns = [
          {
            title: "区",
            key: "zone",
            minWidth: 100,
            align: "center",
          },
          ...this.basicColumn,
        ];
      } else if (name == "4") {
        this.talbeColumns = [
          {
            title: "区",
            key: "zone",
            minWidth: 100,
            align: "center",
          },
          {
            title: "街道",
            key: "street",
            minWidth: 100,
            align: "center",
          },
          ...this.basicColumn,
        ];
      } else {
        this.talbeColumns = [
          {
            title: "区",
            key: "zone",
            minWidth: 100,
            align: "center",
          },
          {
            title: "街道",
            key: "street",
            minWidth: 100,
            align: "center",
          },
          {
            title: "社区",
            key: "community",
            minWidth: 100,
            align: "center",
          },
          ...this.basicColumn,
        ];
      }
      this.orgLevel = name;
      this.page = 1;
      this.search();
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      let params = {
        orgLevel: this.orgLevel,
        page: obj.page,
        pageSize: obj.pageSize,
      };
      if (this.searchDate[0] && this.searchDate[1]) {
        params.startDate = this.$core.formatDate(
          new Date(this.searchDate[0]),
          "yyyy-MM-dd"
        );
        params.endDate = this.$core.formatDate(
          new Date(this.searchDate[1]),
          "yyyy-MM-dd"
        );
      };

      this.$get(
        "/statistic/api/symanage/pc/onlineHome/selectGroupPage",
        params
      )
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.page = res.data.page.currentPage;
            this.pageSize = res.data.page.pageSize;
            this.total = res.data.page.maxCount;
            this.tableData = res.data.page.dataList;
            this.summaryData = {};
            for (let k in res.data.total) {
              if (k == "zone") {
                this.summaryData[k] = {
                  key: k,
                  value: "合计",
                };
              } else {
                this.summaryData[k] = {
                  key: k,
                  value: res.data.total[k],
                };
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  created() {
    this.talbeColumns = [
      {
        title: "区",
        key: "zone",
        minWidth: 100,
        align: "center",
      },
      ...this.basicColumn,
    ];
      this.search();
    this.tableHeight = window.innerHeight - 170 + "";
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>